import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import config from "../../../data/SiteConfig"
import logo from "../../images/logos/logo.svg"
import "./header.module.scss"


const Header = ({ siteTitle }) => (
  <header className="grid-container" style={{ marginTop: "1rem" }}>
    <div className="grid-x">
      <Link to="/" className="logoType cell medium-3">
          <div className="logo">
              <img src={ logo } alt={config.siteTitle} />
          </div>
      </Link>

      <h1 className="cell auto" style={{ margin: '0 0 0 -9999px'}}>{siteTitle}</h1>

      <div className="cell medium-4">
        <nav className="menu align-right">
            <li>
                <Link to="/index" className="button secondary clear">Log In</Link>
            </li>
            <li>
                <Link to="/page-2" className="button alert">Schedule a Demo</Link>
            </li>
        </nav>
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header

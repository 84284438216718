import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import Header from "../components/Header"
import "./layout.scss"
import logo from "../images/logos/logo.svg"
import config from "../../data/SiteConfig"

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }

        activedealer: file(relativePath: { eq: "logos/logo.png" }) {
          childImageSharp {
            fluid(maxWidth: 200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <>
        <Header siteTitle={data.site.siteMetadata.title} />
        <main>{children}</main>
        <footer style={{backgroundColor: "#F0F5F8", borderTop: "#D6E0E6", paddingTop: "80px", fontSize: "0.7rem"}}>
          <div className="grid-container">
            <div className="grid-x grid-margin-x align-bottom">
              <div className="cell medium-3">
                  <img src={ logo } alt={config.siteTitle} />
                  <p>
                    12655 W. Jefferson Blvd. Suite 400<br />
                    Los Angeles, CA 90066
                  </p>
                  <p>
                    Make-A-Deal is crated by <a href="http://activelamp.com">ActiveLAMP</a><br />
                    Copyright © {new Date().getFullYear()}
                  </p>
              </div>
              <div className="cell auto"></div>
              <div className="cell medium-1" style={{paddingBottom: '20px'}}>
                <svg width="34px" height="28px" viewBox="0 0 34 28" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <g id="Welcome" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g id="Desktop-HD" transform="translate(-1270.000000, -4267.000000)" fill-rule="nonzero">
                            <g id="Footer" transform="translate(-7.000000, 4110.000000)">
                                <g id="activedealer-logo" transform="translate(1277.000000, 157.000000)">
                                    <g id="Group" transform="translate(0.000000, 4.000000)" fill="#1EA0F1">
                                        <path d="M22.956805,11.8577576 C22.7364258,5.88569697 17.9524651,1.07627273 11.9621181,0.858848485 L11.9578962,0.834030303 L11.4915957,0.834030303 C11.4711199,0.834030303 11.4495886,0.836151515 11.4310125,0.836151515 L11.0723685,0.836151515 L11.0723685,0.856727273 C5.12825045,1.07393939 0.347245081,5.88357576 0.130876565,11.9029394 L0.105334526,11.9050606 L0.105334526,12.374697 C0.105334526,12.3971818 0.109556351,12.4139394 0.109556351,12.4385455 L0.109556351,12.7968182 L0.126021467,12.7968182 C0.344289803,18.7699394 5.12825045,23.5774545 11.0723685,23.7959394 L11.0723685,23.8165152 L22.9751699,23.8165152 L22.9751699,12.7476061 L22.9751699,12.2779697 L22.9751699,11.8577576 L22.956805,11.8577576 Z M19.1698283,12.2902727 L19.1698283,12.6076061 L19.1698283,19.9917576 L11.2273095,19.9917576 L11.2273095,19.9773333 C7.26322719,19.8311818 4.07279428,16.6241212 3.9252415,12.640697 L3.91299821,12.640697 L3.91299821,12.3976061 C3.91299821,12.385303 3.9108873,12.373 3.9108873,12.3583636 L3.9108873,12.0473939 L3.9252415,12.0431515 C4.07279428,8.03087879 7.26322719,4.82275758 11.2273095,4.67448485 L11.2273095,4.66430303 L11.46922,4.66430303 C11.4795635,4.66430303 11.4939177,4.66006061 11.5051055,4.66006061 L11.8177317,4.66006061 L11.8177317,4.67660606 C15.8136887,4.81978788 19.0060215,8.02875758 19.1516744,12.014303 L19.1660286,12.014303 L19.1660286,12.2902727 L19.1698283,12.2902727 Z" id="Shape"></path>
                                    </g>
                                    <g id="Group" transform="translate(11.000000, 0.000000)" fill="#364760">
                                        <path d="M19.1972109,0.0103939394 L19.1972109,4.83424242 L11.9936953,4.83424242 L11.5266797,4.83424242 L11.1090234,4.83424242 L11.1090234,4.85693939 C5.16796875,5.07415152 0.388335938,9.88357576 0.174445313,15.9031515 L0.148921875,15.9052727 L0.148921875,16.3749091 C0.148921875,16.3973939 0.15103125,16.4141515 0.15103125,16.4387576 L0.15103125,16.7970303 L0.168539063,16.7970303 C0.386648438,22.7701515 5.17028906,27.5776667 11.1520547,27.7940303 L11.1562734,27.8165152 L11.6211797,27.8165152 C11.6416406,27.8165152 11.6642109,27.8122727 11.6783437,27.8122727 L12.0388359,27.8122727 L12.0388359,27.7959394 C17.9765156,27.5776667 22.7612109,22.7701515 22.9763672,16.7968182 L23.0010469,16.7968182 L23.0010469,8.66006061 L23.0010469,4.83424242 L23.0010469,0.0103939394 L19.1972109,0.0103939394 Z M19.1972109,16.6404848 L19.1849766,16.6404848 C19.0436484,20.6281515 15.8483672,23.8307576 11.8869609,23.9792424 L11.8869609,23.9917576 L11.6494453,23.9917576 C11.637,23.9917576 11.6207578,23.9938788 11.6085234,23.9938788 L11.2971797,23.9938788 L11.2971797,23.9773333 C7.30307812,23.8330909 4.11096094,20.6283636 3.96667969,16.640697 L3.95528906,16.640697 L3.95528906,16.3976061 C3.95528906,16.385303 3.95528906,16.373 3.95528906,16.3583636 L3.95528906,16.0473939 L3.9706875,16.0431515 C4.11307031,12.0308788 7.30539844,8.82275758 11.2687031,8.67448485 L11.2687031,8.66006061 L11.5431328,8.66006061 L11.856375,8.66006061 L19.1993203,8.66006061 L19.1993203,16.6404848 L19.1972109,16.6404848 L19.1972109,16.6404848 Z" id="Shape"></path>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
              </div>
            </div>
          </div>
          
        </footer>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
